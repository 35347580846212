@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toolbar, .color-picker, .context-menu {
  background-color: #2c2c2c; /* Dark background */
  color: #f1f1f1; /* Light text */
}

.toolbar button, .color-picker button, .context-menu button {
  color: #f1f1f1;
  background-color: #3d3d3d; /* Slightly lighter background */
}

.toolbar button:hover, .color-picker button:hover, .context-menu button:hover {
  background-color: #4c4c4c;
}

.toolbar input[type="range"] {
  background-color: #4c4c4c;
}
